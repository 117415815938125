import axios from 'axios';
import { getAccessToken } from './auth.js';
import { CONTENTS_MS_URL } from '../../constants/api';

export const fetchSection = async (authData, args) => {
  const {
    sectionId,
    evaluationSessionId
  } = args;

  const url = CONTENTS_MS_URL;

  const data = JSON.stringify({
    query: `
      query Section($sectionId: ID!, $evaluationSessionId: ID) {
        section(id: $sectionId, evaluationSessionId: $evaluationSessionId) {
          id
          name
          type
          lesson {
            id
            name
            communicationSkill {
              id
              name
              description
            }
          }
        }
      }
    `,
    variables: {
      sectionId,
      evaluationSessionId
    }
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  const { data: res } = await axios(axiosConfig);

  return res.data.section;
};
