import { call, select, put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  SEND_RESPONSE_SUCCESS,
  END_MODULE_SUCCESS,
  CUSTOM_LEAVE_MODULE,
  GO_TO_END_MODULE, GO_TO_CUSTOM_END
} from '../actions';
import CMI5Maskott from '../services/CMI5Maskott';
import {
  getLtiInfo,
  getCmi5Activity,
  getModulePoints,
  getAccessToken
} from '../selectors';
import logger from '@frello-tech/front-utils/dist/utils/logger';

function * flow () {
  const user = yield select(getLtiInfo);
  if (user.isMaskoot) {
    yield takeLatest(END_MODULE_SUCCESS, endXapiSequence);
    yield takeLeading(GO_TO_CUSTOM_END, redirectToReturnUrl);
    yield takeLatest(SEND_RESPONSE_SUCCESS, sendAnsweredStatement);
    yield takeLeading(CUSTOM_LEAVE_MODULE, leaveModule);
  }
}

export function * initCmi5Maskoot () {
  try {
    const user = yield select(getLtiInfo);
    if (user.isMaskoot) {
      const auth = yield call(select(getAccessToken));
      const cmi5 = new CMI5Maskott({ ...user, auth });
      yield call(cmi5.startXapiSequence);
      if (window.parent) {
        window.parent.postMessage(JSON.stringify({ subject: 'lti.removeBorder' }), '*');
      }
      return true;
    } else {
      return false;
    }
  } catch (e) {
    yield call(console.log, 'initCmi5Maskoot error');
    logger.error(e);
    return false;
  }
}

export function * sendAnsweredStatement ({ payload: { point, score, currentActivity } }) {
  try {
    const scaled = point > 0 ? Math.round((1 / point) * score) : 0;
    const cmi5Data = yield select(getCmi5Activity, currentActivity, {
      success: parseFloat(score) === parseFloat(point),
      score: {
        scaled,
        raw: Math.round(score),
        max: point,
        min: 0
      }
    });

    const user = yield select(getLtiInfo);
    const auth = yield call(select(getAccessToken));
    const cmi5 = new CMI5Maskott({ ...user, auth });
    yield call(cmi5.sendAnswered, cmi5Data);
  } catch (e) {
    yield call(console.log, 'sendAnsweredStatement error');
    logger.error(e);
  }
}

export function * endXapiSequence ({ payload: { survey, isFinish } }) {
  try {
    if (!isFinish) {
      const points = yield select(getModulePoints);
      const score = survey.score && !isNaN(points) && points > 0 ? (100 / points) * survey.score : undefined;
      const user = yield select(getLtiInfo);
      const auth = yield call(select(getAccessToken));
      const cmi5 = new CMI5Maskott({ ...user, auth });
      yield call(cmi5.endXapiSequence, { score });
    } else {
      /**
      todo: patch provisioire pour faire fonctionner maskott a virer en iteration 2
      a supprimer
      **/
      const points = yield select(getModulePoints);
      const score = survey.score && !isNaN(points) && points > 0 ? (100 / points) * survey.score : 100;
      const user = yield select(getLtiInfo);
      const auth = yield call(select(getAccessToken));
      const cmi5 = new CMI5Maskott({ ...user, auth });
      yield call(cmi5.endXapiSequence, { score });
    }
  } catch (e) {
    yield call(console.log, 'endXapiSequence error');
    logger.error(e);
  }
}

export function * terminatedStatementSent () {
  try {
    const user = yield select(getLtiInfo);
    const auth = yield call(select(getAccessToken));
    const cmi5 = new CMI5Maskott({ ...user, auth });
    yield call(cmi5.terminatedStatementSent);
  } catch (e) {
    yield call(console.log, 'endXapiSequence error');
    logger.error(e);
  }
}

function * redirectToReturnUrl () {
  try {
    const user = yield select(getLtiInfo);
    const auth = yield call(select(getAccessToken));
    const cmi5 = new CMI5Maskott({ ...user, auth });

    if (cmi5.returnURL) { // vérifier que c'est une url valide
      document.location.href = cmi5.returnURL;
    } else {
      yield put({ type: GO_TO_END_MODULE });
    }
  } catch (e) {
    yield call(console.log, 'redirectToReturnUrl error');
    logger.error(e);
  }
}

function * leaveModule () {
  yield call(terminatedStatementSent);
  yield call(redirectToReturnUrl);
}

export default flow;
