import { call, takeLatest } from 'redux-saga/effects';
import { NAVIGATE } from '../actions';
import Router from 'next/router';
import logger from '@frello-tech/front-utils/dist/utils/logger';

function * flow () {
  yield takeLatest(NAVIGATE, navigate);
}

export function * navigate ({ payload: { as, href } }) {
  try {
    window.onbeforeunload = null;
    yield call(Router.push, href, as);
  } catch (e) {
    logger.error(e);
    yield call(console.log, 'cannot navigate to ' + href);
  }
}

export default flow;
