import Immutable from 'seamless-immutable';
import {
  SAVE_NAVIGATION_BEFORE_MODULE,
  SET_CURRENT_MODULE,
  SET_CURRENT_LOCAL_SUCCESS,
  JOIN_CLASSROOM_SUCCESS,
  JOIN_CLASSROOM_FAIL,
  SET_SURVEY_IS_LOADED_FALSE,
  SET_SURVEY_IS_LOADED_TRUE,
  SET_RESUME_TRUE,
  SET_MODULE_RESOLUTION_MODE,
  SET_LOADED,
  SET_LOADING,
  SET_IMPERSONATED,
  SET_CURRENT_ACTIVITY_INDEX,
  SET_ACTIVE_DROPZONE,
  SET_CURRENT_CLASSROOM_ID,
  SET_CURRENT_SECTION,
  SET_SHOW_BEFORE_LEAVE_WARNING_TRUE,
  SET_SHOW_BEFORE_LEAVE_WARNING_FALSE,
  SET_TRANSITION_MODE_TRUE,
  SET_CORRECTION_MODE_ONLY,
  RESET_ACTIVITY_TIME,
  INCREMENT_ACTIVITY_TIME,
  AUTO_CONNECT_IFRAME
} from '../actions';

export const INITIAL_STATE = Immutable({
  backBeforeModule: '',
  currentModule: {},
  currentSection: {},
  currentEvaluationSession: {},
  currentModuleVersion: {},
  currentActivityIndex: null,
  currentLocale: 'fr-FR',
  joinClassroomError: false,
  appIsReady: false,
  surveyIsLoaded: false,
  moduleCompletionMode: 'libre',
  resume: false,
  transitionMode: false,
  impersonated: false,
  loading: [],
  activeDropzone: 0,
  showBeforeLeaveWarning: false,
  correctionModeOnly: false,
  activityTime: 0,
  tabId: undefined
});

const AVAILABLE_EXTENSIONS = [
  'libre',
  'devoir',
  'previsualisation',
  'recommandation',
  'evaluation'
];

const models = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  case AUTO_CONNECT_IFRAME: {
    const {
      moduleId,
      activityIndex,
      extension,
      classroomId,
      evaluationSessionId,
      sectionId,
      moduleVersionId,
      locale,
      tabId,
      redirectURI,
      impersonates,
      resume,
      transition
    } = payload;
    return {
      ...state,
      ...moduleId && { currentModule: { moduleId } },
      ...activityIndex && { currentActivityIndex: activityIndex },
      ...extension && AVAILABLE_EXTENSIONS.includes(extension) && { moduleCompletionMode: extension },
      ...classroomId && { currentClassroomId: classroomId },
      ...evaluationSessionId && { currentEvaluationSession: { evaluationSessionId } },
      ...sectionId && {
        currentSection: { sectionId },
        showBeforeLeaveWarning: true
      },
      ...moduleVersionId && { currentModuleVersion: { moduleVersionId } },
      ...locale && { currentLocale: locale },
      ...tabId && { tabId },
      ...redirectURI && { redirectURI },
      ...impersonates && {
        impersonates,
        impersonated: true
      },
      ...resume && { resume: true },
      ...transition && { transitionMode: true }
    };
  }
  case SET_CURRENT_MODULE:
    return { ...state, currentModule: { ...payload }, surveyIsLoaded: false };
  case SET_CURRENT_SECTION:
    return { ...state, currentSection: { ...payload } };
  case SET_CURRENT_ACTIVITY_INDEX:
    return { ...state, currentActivityIndex: payload.activityIndex };
  case SAVE_NAVIGATION_BEFORE_MODULE:
    return { ...state, backBeforeModule: { ...payload } };
  case SET_CURRENT_LOCAL_SUCCESS:
    return { ...state, currentLocale: payload.locale };
  case JOIN_CLASSROOM_SUCCESS:
    return { ...state, joinClassroomError: false };
  case JOIN_CLASSROOM_FAIL:
    return { ...state, joinClassroomError: true };
  case SET_SURVEY_IS_LOADED_FALSE:
    return { ...state, surveyIsLoaded: false };
  case SET_SURVEY_IS_LOADED_TRUE:
    return { ...state, surveyIsLoaded: true };
  case SET_RESUME_TRUE:
    return { ...state, resume: true };
  case SET_TRANSITION_MODE_TRUE:
    return { ...state, transitionMode: true };
  case SET_MODULE_RESOLUTION_MODE:
    return AVAILABLE_EXTENSIONS.includes(payload.extension)
      ? { ...state, moduleCompletionMode: payload.extension }
      : state;
  case SET_LOADING: {
    const resourceIndex = state.loading.indexOf(payload.resource);

    return resourceIndex > -1
      ? state
      : {
        ...state,
        loading: [
          ...state.loading,
          payload.resource
        ]
      };
  }
  case SET_LOADED: {
    const resourceIndex = state.loading.indexOf(payload.resource);
    const loading = state.loading;

    if (resourceIndex > -1) {
      loading.splice(resourceIndex, 1);
    }

    return resourceIndex
      ? state
      : {
        ...state,
        loading
      };
  }
  case SET_IMPERSONATED:
    return {
      ...state,
      impersonated: payload.impersonated
    };
  case SET_ACTIVE_DROPZONE:
    return { ...state, activeDropzone: payload.newActiveDropzone };
  case SET_CURRENT_CLASSROOM_ID:
    return { ...state, currentClassroomId: payload.classroomId };
  case SET_SHOW_BEFORE_LEAVE_WARNING_TRUE:
    if (state.moduleCompletionMode !== 'evaluation') {
      return state;
    }
    return { ...state, showBeforeLeaveWarning: true };
  case SET_SHOW_BEFORE_LEAVE_WARNING_FALSE:
    return { ...state, showBeforeLeaveWarning: false };
  case SET_CORRECTION_MODE_ONLY:
    return { ...state, correctionModeOnly: true };
  case RESET_ACTIVITY_TIME:
    return { ...state, activityTime: 0 };
  case INCREMENT_ACTIVITY_TIME:
    return { ...state, activityTime: state.activityTime + 1 };
  default:
    return state;
  }
};

export default models;
