/**
 * @param {URL} url
 * @param {*} data
 */
const configureURL = (url, data) => {
  const {
    tabId,
    extension,
    classroomId,
    moduleVersionId,
    sectionId,
    surveyId,
    activityIndex,
    evaluationSessionId,
    transition,
    redirectURI,
    impersonates
  } = data;

  if (tabId) { url.searchParams.set('tab', tabId); }
  if (extension) { url.searchParams.set('extension', extension); }
  if (classroomId) { url.searchParams.set('classroom', classroomId); }
  if (moduleVersionId) { url.searchParams.set('version', moduleVersionId); }
  if (sectionId) { url.searchParams.set('section', sectionId); }
  if (surveyId) { url.searchParams.set('survey', surveyId); }
  if (activityIndex >= 0) { url.searchParams.set('index', activityIndex); }
  if (evaluationSessionId) { url.searchParams.set('evaluation_session', evaluationSessionId); }
  if (transition) { url.searchParams.set('transition', true); }
  if (redirectURI) { url.searchParams.set('redirect_uri', redirectURI); }
  if (impersonates) { url.searchParams.set('impersonates', impersonates); }
};

export default configureURL;
