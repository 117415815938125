import logger from '@frello-tech/front-utils/dist/utils/logger';
class SoundEffect {
  constructor () {
    if (SoundEffect.instance) {
      return SoundEffect.instance;
    }
    this.sounds = {};
    SoundEffect.instance = this;
  }

  loadSounds = () => {
    try {
      if (typeof window !== 'undefined' && typeof Audio !== 'undefined') {
       this.sounds.victory = new Audio('https://s3.eu-west-3.amazonaws.com/frello.eu/sounds/victory.mp3');
       this.sounds.loose = new Audio('https://s3.eu-west-3.amazonaws.com/frello.eu/sounds/loose.wav');
       this.sounds.endModule = new Audio('https://s3.eu-west-3.amazonaws.com/frello.eu/sounds/endModule.mp3');
      }
    } catch (e) {
      console.log('[Services][SoundEffect][loadSound] error');
      logger.error(e);
    }
  };

  playVictory = () => {
    try {
      this.sounds.victory.play();
    } catch (e) {
      console.log(' exception sound play victory');
      logger.error(e);
    }
  };

  playLoose = () => {
    try {
      this.sounds.loose.play();
    } catch (e) {
      console.log(' exception sound play loose');
      logger.error(e);
    }
  };

  playEndModule = () => {
    try {
      this.sounds.endModule.play();
    } catch (e) {
      console.log(' exception sound play endModule');
      logger.error(e);
    }
  };
}

export default new SoundEffect();
