import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CUSTOM_LEAVE_MODULE,
  SET_CURRENT_LOCALE,
  SET_CURRENT_LOCAL_SUCCESS,
  SET_CURRENT_SECTION,
  TRANSITION_CONTINUE
} from '../actions';
import { setI18nConfig } from '../services/i18n';
import {
  getModuleResolutionData
} from '../selectors';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import { navigate } from './navigate';
import { getCurrentSectionId } from '@frello-tech/front-utils/dist/store/selectors';
import configureURL from '../utils/configure-url';

function * flow () {
  yield initLocale();
  yield takeLatest(SET_CURRENT_LOCALE, changeLocale);
  yield takeLatest(TRANSITION_CONTINUE, transitionContinue);
}

function * initLocale () {
  try {
    const locale = yield select(state => state.ui.currentLocale);
    if (locale) {
      yield call(setI18nConfig, locale);
    }
  } catch (e) {
    logger.error(e);
    console.log('default locale');
  }
}

function * changeLocale ({ payload: { locale } }) {
  try {
    yield call(setI18nConfig, locale);
    yield put({ type: SET_CURRENT_LOCAL_SUCCESS, payload: { locale } });
  } catch (e) {
    logger.error(e);
    console.log('erreur change local :' + locale);
  }
}

function * transitionContinue ({ payload }) {
  try {
    if (payload) {
      const {
        sectionData,
        continueOption
      } = payload;

      const {
        type,
        moduleId
      } = continueOption || {};

      if ((type === 'session' && sectionData.evaluationSession.status !== 'started') || !type) {
        return yield put({ type: CUSTOM_LEAVE_MODULE, payload: { noConfirm: true } });
      }

      const sectionId = yield select(getCurrentSectionId);

      let url;

      switch (type) {
      case 'section':
        url = new URL(`/section/${sectionId}/start`, window.location.origin);
        break;
      case 'session':
        yield put({ type: SET_CURRENT_SECTION, payload: { sectionId: sectionData.evaluationSession.nextSection.id } });
        url = new URL(`/section/${sectionData.evaluationSession.nextSection.id}/start`, window.location.origin);
        break;
      case 'homework':
        url = new URL(`/module/${moduleId}/start`, window.location.origin);
        break;
      default:
        url = new URL(`/module/${moduleId}/start`, window.location.origin);
        break;
      }

      const moduleResolutionData = yield select(getModuleResolutionData);
      moduleResolutionData.transition = true;
      if (type === 'homework') {
        moduleResolutionData.extension = 'devoir';
      }

      configureURL(url, moduleResolutionData);

      yield call(navigate, {
        payload: {
          as: url.toString(),
          href: url.toString()
        }
      });
    } else {
      return yield put({ type: CUSTOM_LEAVE_MODULE, payload: { noConfirm: true } });
    }
  } catch (error) {
    logger.error(error);
    console.log('Error while transitioning.');
  }
}

export default flow;
