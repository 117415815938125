import axios from 'axios';
import { getAccessToken } from './auth.js';
import { ACCOUNTS_MS_URL } from '../../constants/api';
import logger from '@frello-tech/front-utils/dist/utils/logger.js';

export const getCurrentUserFromMS = async ({ authData }) => {
  const url = ACCOUNTS_MS_URL;

  const data = JSON.stringify({
    query: `query GetCurrentUser {
      getCurrentUser {
        id
        email
        firstName
        lastName
        roles
        type
        inscriptionDate
      }
    }`
  });

  const axiosConfig = {
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${await getAccessToken(authData)}`,
      'Content-Type': 'application/json'
    },
    data
  };

  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    logger.error(error);
    throw error;
  }
};
