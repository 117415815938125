import axios from 'axios';
import { getAuthorization } from './auth.js';

import { URL_API_FRELLO } from '../../constants/api';
import logger from '@frello-tech/front-utils/dist/utils/logger';

export const sendGradeToComsumer = async (authData, { sessionID, grade }) => {
  const headers = await getAuthorization(authData);
  return axios.post(`${URL_API_FRELLO}lti/session/${sessionID}/grade/${grade}/`,
    {},
    headers
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(`can not contact ${URL_API_FRELLO}lti/session/${sessionID}/grade/${grade}/`);
      logger.error(error);
      return [];
    });
};
