import Immutable from 'seamless-immutable';
import {
  FETCH_MODULES_SUCCESS,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_SURVEY_SUCCESS,
  END_MODULE_SUCCESS,
  FETCH_PROGRAMS_SUCCESS,
  SEND_RESPONSE_SUCCESS,
  FETCH_HOMEWORKS_SUCCESS,
  FETCH_RECOMMENDATIONS_SUCCESS,
  ADD_ENTITIES,
  FETCH_TRANSITION_SUCCESS
} from '../actions';
import {
  getModulesFromPrograms,
  getModuleFromHomeworks,
  getModulesFromRecommendations,
  updateSurveyInModule,
  getTypeFromActivity
} from '../selectors';
import pullAllWith from 'lodash/pullAllWith';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import uniqBy from 'lodash/uniqBy';
import { ACTIVITY_PROPERTIES_NAMES } from '../constants/api';
export const INITIAL_STATE = Immutable({
  entities: {},
  classrooms: [],
  modules: [],
  activities: [],
  survey: {},
  programs: [],
  homeworks: [],
  recommendations: [],
  transition: undefined
});

// todo: put in selectors
const sortArrayByOrder = (a, b) => {
  return (a.bloc_question || a).bloc.ordre - (b.bloc_question || b).bloc.ordre;
};

const models = (state = INITIAL_STATE, { type, payload, entities }) => {
  switch (type) {
  case ADD_ENTITIES: {
    const models = entities;
    const newState = cloneDeep(state);
    return mergeWith(newState, models);
  }

  case FETCH_MODULES_SUCCESS:
    state = { ...state, modules: uniqBy([...state.modules, ...payload.modules], 'id') };
    return state;

  case FETCH_ACTIVITIES_SUCCESS: {
    const activities = payload.activities.map(activity => {
      const type = getTypeFromActivity(activity);
      if (ACTIVITY_PROPERTIES_NAMES[type] !== undefined && 'questionsBlockName' in ACTIVITY_PROPERTIES_NAMES[type]) {
        const { questionsBlockName } = ACTIVITY_PROPERTIES_NAMES[type];
        const activityLength = activity[questionsBlockName].length;
        const order = Array.from({ length: activityLength }, (_, i) => i + 1);

        return {
          ...activity,
          [questionsBlockName]: questionsBlockName && activity[questionsBlockName].map(element => ({
            ...element,
            order: order.splice(Math.floor(Math.random() * order.length), 1)[0]
          }))
        };
      } else return activity;
    });

    state = { ...state, activities: (activities || []).sort(sortArrayByOrder) };
    return state;
  }
  case END_MODULE_SUCCESS:
  case FETCH_SURVEY_SUCCESS:
    state = {
      ...state,
      modules: [...updateSurveyInModule(state.modules, payload.survey)],
      survey: payload.survey
    };
    return state;
  case SEND_RESPONSE_SUCCESS :
    state = {
      ...state,
      survey: {
        ...state.survey,
        reponses: [
          ...pullAllWith(state.survey.reponses, payload.result, (a, b) => a.id === b.id),
          ...payload.result
        ]
      }
    };
    return state;
  case FETCH_PROGRAMS_SUCCESS:
    return {
      ...state,
      programs: payload.programs,
      modules: uniqBy([...getModulesFromPrograms(payload.programs), ...state.modules], 'id')
    };
  case FETCH_HOMEWORKS_SUCCESS:
    return {
      ...state,
      homeworks: [...payload.homeworks],
      modules: uniqBy([...getModuleFromHomeworks(payload.homeworks), ...state.modules], 'id')// check duplicate module to avoid too much data
    };
  case FETCH_RECOMMENDATIONS_SUCCESS:
    return {
      ...state,
      recommendations: [...payload.recommendations],
      modules: uniqBy([...getModulesFromRecommendations(payload.recommendations), ...state.modules], 'id')
    };
  case FETCH_TRANSITION_SUCCESS:
    state = { ...state, transition: payload.transition };
    return state;

  default:
    return state;
  }
};

export default models;
