import React, { useEffect, useState } from 'react';
import {
  setSlowConnectionCallback,
  setBackToCorrectConnectionCallback,
  setSlowConnectionThreshold,
  setConnectionVerificationInterval,
  discardConnectionCheck
} from '@frello-tech/front-utils/dist/utils/connectionQuality';
import { translate } from '../../js/services/i18n';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ConnectionQuality = () => {
  const [slowConnectionModalOpen, setSlowConnectionModalOpen] = useState < boolean > (false);
  const [backToCorrectConnection, setBackToCorrectConnection] = useState < boolean > (false);

  const handleBackToCorrectConnection = () => {
    setBackToCorrectConnection(true);
    setTimeout(() => {
      setSlowConnectionModalOpen(false);
      setTimeout(() => {
        setBackToCorrectConnection(false);
      }, 100);
    }, 2000);
  };

  useEffect(() => {
    setSlowConnectionCallback(() => {
      setSlowConnectionModalOpen(true);
    });
    setBackToCorrectConnectionCallback(() => {
      handleBackToCorrectConnection();
    });
    setSlowConnectionThreshold(2000);
    setConnectionVerificationInterval(5000);
  }, []);

  const handleClose = () => {
    discardConnectionCheck();
    setSlowConnectionModalOpen(false);
  };

  // const handleRefresh = () => {
  //   window.location.reload();
  // };

  const severity = backToCorrectConnection ? 'success' : 'warning';

  return (<Snackbar
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    open={slowConnectionModalOpen}
    onClose={handleClose}
  >
    <Alert
      severity={severity}
      elevation={1}
      onClose={handleClose}
      action={<>
        <IconButton
          aria-label="Close snackbar button"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </>}
    >
      <AlertTitle>
        {translate('connectionQuality.title')}
      </AlertTitle>
      {translate(`connectionQuality.${backToCorrectConnection ? 'backToNormal' : 'abnormal'}`)}
    </Alert>
  </Snackbar>);
};
export default ConnectionQuality;
