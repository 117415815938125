import { call, select, takeLatest, takeLeading, put } from 'redux-saga/effects';
import {
  SEND_RESPONSE_SUCCESS,
  END_MODULE_SUCCESS,
  CUSTOM_LEAVE_MODULE,
  GO_TO_CUSTOM_END,
  GO_TO_NEXT_ACTIVITY,
  SET_CURRENT_LOCAL_SUCCESS,
  SET_LOADING,
  SET_LOADED,
  SEND_RESPONSE
} from '../actions';
import CMI5Service from '../services/CMI5';
import {
  getCmi5Info,
  getCmi5Activity,
  getModulePoints,
  getCurrentNBactivities,
  getFirstActivityNotDone
} from '../selectors';
import { setI18nConfig } from '../services/i18n';
import logger from '@frello-tech/front-utils/dist/utils/logger';

function * flow () {
  const isCMI5inter = yield call(retrieveLaunchData);// en remplacer par retrieveLaunchData
  if (isCMI5inter) {
    yield initLocale();
    yield takeLatest(END_MODULE_SUCCESS, endXapiSequence);
    yield takeLatest(GO_TO_CUSTOM_END, redirectToReturnUrl);
    yield takeLatest(SEND_RESPONSE_SUCCESS, sendAnsweredStatement);
    yield takeLeading(GO_TO_NEXT_ACTIVITY, incrementActivityNumber);
    yield takeLeading(CUSTOM_LEAVE_MODULE, leaveModule);
  }
}

function * initLocale () {
  try {
    const user = yield select(getCmi5Info);
    if (user.isCMI5Inter) {
      let locale = 'fr';
      const cmi5 = new CMI5Service(user);
      const learnersPreferences = yield call(cmi5.getLearnerPreferences);
      const locales = learnersPreferences.contents.languagePreference;
      if (locales) {
        locale = locales.split(',')[0];
      }
      yield call(setI18nConfig, locale);
      yield put({ type: SET_CURRENT_LOCAL_SUCCESS, payload: { locale } });
    }
  } catch (e) {
    logger.error(e);
    console.log('default local');
  }
}

export function * getCurentActivity () {
  try {
    const user = yield select(getCmi5Info);
    if (user.isCMI5Inter) {
      const cmi5 = new CMI5Service(user);
      const currentActivity = yield call(cmi5.getCurrentActivity);
      return currentActivity + 1;
    } else {
      return yield select(getFirstActivityNotDone);
    }
  } catch (e) {
    yield call(console.log, 'retrieveLaunchData error');
    logger.error(e);
    return false;
  }
}

export function * retrieveLaunchData () {
  try {
    const user = yield select(getCmi5Info);
    if (user.isCMI5Inter) {
      const cmi5 = new CMI5Service(user);
      yield call(cmi5.startXapiSequence);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    yield call(console.log, 'retrieveLaunchData error');
    logger.error(e);
    return false;
  }
}

export function * incrementActivityNumber ({ payload: { currentActivity } }) {
  const user = yield select(getCmi5Info);
  const cmi5 = new CMI5Service(user);
  yield call(cmi5.setCurrentActivity, { activityNumber: currentActivity + 1 });
}

export function * sendAnsweredStatement ({ payload: { point = 5, score = 0, currentActivity } }) {
  try {
    yield put({ type: SET_LOADING, payload: { resource: SEND_RESPONSE } });
    const scaled = point > 0 ? (Math.round((1 / point) * 100) / 100) * score : 0;
    const cmi5Data = yield select(getCmi5Activity, currentActivity, {
      success: score === point,
      score: {
        scaled,
        raw: Math.round(score),
        max: point,
        min: 0
      }
    });
    if (cmi5Data === false) {
      yield put({ type: SET_LOADED, payload: { resource: SEND_RESPONSE } });
      return false;
    }
    const user = yield select(getCmi5Info);
    const cmi5 = new CMI5Service(user);
    yield call(cmi5.sendAnswered, { ...cmi5Data, currentActivity });
    const nbActivities = yield select(getCurrentNBactivities);
    const progress = Math.round(((currentActivity + 1) / nbActivities) * 100);

    yield call(cmi5.progressStatementSent, { completion: score === point, progress, currentActivity });
    yield call(cmi5.setCurrentActivity, { activityNumber: currentActivity + 1 });
    yield put({ type: SET_LOADED, payload: { resource: SEND_RESPONSE } });
  } catch (e) {
    yield call(console.log, 'sendAnsweredStatement error');
    logger.error(e);
  }
}

export function * endXapiSequence ({ payload: { survey, isFinish } }) {
  try {
    if (!isFinish) {
      const points = yield select(getModulePoints);
      const score = survey.score ? survey.score : undefined;
      const user = yield select(getCmi5Info);
      const cmi5 = new CMI5Service(user);
      yield call(cmi5.endXapiSequence, { score, points });
    }
  } catch (e) {
    yield call(console.log, 'endXapiSequence error');
    logger.error(e);
  }
}

export function * terminatedStatementSent () {
  try {
    yield put({ type: SET_LOADING, payload: { resource: SEND_RESPONSE } });
    const user = yield select(getCmi5Info);
    const cmi5 = new CMI5Service(user);
    yield call(cmi5.terminatedStatementSent);
    yield put({ type: SET_LOADED, payload: { resource: SEND_RESPONSE } });
  } catch (e) {
    yield call(console.log, 'endXapiSequence error');
    logger.error(e);
  }
}

function * redirectToReturnUrl () {
  const user = yield select(getCmi5Info);
  const cmi5 = new CMI5Service(user);
  if (cmi5.returnURL) { // vérifier que c'est une url valide
    document.location.href = cmi5.returnURL;
  }
}

function * leaveModule () {
  yield call(terminatedStatementSent);
  yield call(redirectToReturnUrl);
}

export default flow;
