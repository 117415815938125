import CMI5 from './CMI5';
import { URL_API_FRELLO } from '../constants/api';
import TinCan from 'tincanjs';
import logger from '@frello-tech/front-utils/dist/utils/logger';

const roundScore = score => Math.round(score * 100) / 100;

class CMI5Maskott {
  constructor (cfg) {
    if (CMI5.instance) {
      return CMI5.instance;
    }

    this.initTinCan(cfg);
    CMI5.instance = this;
  }

  lrs
  activity
  agent
  context

  initTinCan = (cfg) => {
    try {
      const {
        auth,
        custom_xapi_actor_account_name,
        custom_xapi_actor_account_homePage,
        custom_xapi_activity_id,
        custom_xapi_context_extensions_sessionid,
        custom_xapi_context_contextActivities_parent_id,
        custom_xapi_context_contextActivities_parent_definition_type
      } = cfg;

      this.agent = new TinCan.Agent({
        account: {
          name: custom_xapi_actor_account_name,
          homePage: custom_xapi_actor_account_homePage
        }
      });

      this.masteryscore = 0.7;

      this.activity = new TinCan.Activity(
        {
          id: custom_xapi_activity_id,
          definition: {
            type: 'http://frello.eu/module_lti_test',
            name: {
              'en-US': 'Module Frello de test',
              'fr-FR': 'module Frello de test'
            }
          }
        });
      this.attemptActivity = new TinCan.Activity({ id: custom_xapi_context_extensions_sessionid });

      this.context = {
        context: {
          extensions: {
            'https://w3id.org/xapi/cmi5/context/extensions/sessionid': custom_xapi_context_extensions_sessionid
          },
          contextActivities: {
            parent: [
              {
                id: custom_xapi_context_contextActivities_parent_id,
                objectType: 'Activity',
                definition: {
                  type: custom_xapi_context_contextActivities_parent_definition_type
                }
              }
            ]
          }
        }
      };

      this.lrs = new TinCan.LRS(
        {
          endpoint: `${URL_API_FRELLO}proxy/maskott/`,
          auth,
          allowFail: false,
          version: '1.0.0'
        }
      );
    } catch (ex) {
      logger.error(ex);
      return null;
    }
  }

  sendStatement = statement => {
    return new Promise((resolve, reject) => {
      this.lrs.saveStatement(statement,
        {
          callback: (err, xhr) => {
            if (err) {
              logger.error(err);
              if (xhr !== null) {
                const { responseText, status } = xhr;
                console.log(`Failed to save statement: ${responseText}(${status})`);
              }
              reject(err);
            } else {
              resolve({});
            }
          }
        });
    });
  }

  startXapiSequence = async () => {
    await this.initializedStatement();
  }

  getCurrentActivity = async () => {
    return 0;
  }

  initializedStatement = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/initialized',
      display: {
        'en-US': 'initialized',
        'fr-FR': 'a démarré'
      }
    };

    this.initializedDate = new Date();

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(this.initializedDate)
      }
    );

    await this.sendStatement(statement);
  }

  attemptStatement = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/attempt',
      display: {
        'en-US': 'attempt',
        'fr-FR': 'a essayé'
      }
    };

    this.initializedDate = new Date();

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(this.initializedDate)
      }
    );

    await this.sendStatement(statement);
  }

  resumedStatement = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/resumed',
      display: {
        'en-US': 'resumed',
        'fr-FR': 'a repris'
      }
    };

    this.initializedDate = new Date();

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(this.initializedDate)
      }
    );

    await this.sendStatement(statement);
  }

  experiencedStatement = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/experienced',
      display: {
        'en-US': 'experienced',
        'fr-FR': 'a consulté'
      }
    };

    this.initializedDate = new Date();

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(this.initializedDate)
      }
    );

    await this.sendStatement(statement);
  }

  sendAnswered = async ({ result, activityCMI5 }) => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/answered',
      display: {
        'en-US': 'answered',
        'fr-FR': 'a répondu'
      }
    };

    const completedDate = new Date();
    const object = {
      ...this.activity,
      ...activityCMI5
    };

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object,
        result,
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(completedDate)
      });

    await this.sendStatement(statement);
  }

  completedStatementSent = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: {
        'en-US': 'completed',
        'fr-FR': 'a finalisé'
      }
    };

    const completedDate = new Date();
    const durationMS = completedDate.getTime() - this.initializedDate.getTime();
    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        result: {
          completion: true,
          duration
        },
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(completedDate)
      });

    await this.sendStatement(statement);
  }

  suspendedStatement = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/suspended',
      display: {
        'en-US': 'suspended',
        'fr-FR': 'a suspendu'
      }
    };

    this.initializedDate = new Date();

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        ...this.context,
        timestamp: TinCan.Utils.getISODateString(this.initializedDate)
      }
    );

    await this.sendStatement(statement);
  }

  sendPassedStatement = async (score = 100) => {
    const verb = new TinCan.Verb('passed');
    const newScore = roundScore(score);
    const passedDate = new Date();
    const durationMS = passedDate.getTime() - this.initializedDate.getTime();
    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    // const category = [
    //   {
    //     id: 'https://w3id.org/xapi/cmi5/context/categories/moveon',
    //     objectType: 'Activity'
    //   }];
    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        result: {
          score: {
            scaled: newScore / 100,
            raw: newScore,
            min: 0,
            max: 100
          },
          success: true,
          duration
        },
        context: {
          ...this.context.context,
          extensions: {
            ...this.context.context.extensions,
            'https://w3id.org/xapi/cmi5/context/extensions/masteryscore': this.masteryscore
          }
        },
        timestamp: TinCan.Utils.getISODateString(passedDate)
      });

    await this.sendStatement(statement);
  }

  failedStatementSent = async (score = 0) => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/failed',
      display: {
        'en-US': 'failed',
        'fr-FR': 'a échoué'
      }
    };

    const newScore = roundScore(score);
    const failedDate = new Date();
    const durationMS = failedDate.getTime() - this.initializedDate.getTime();
    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    // const category = [
    //   {
    //     id: 'https://w3id.org/xapi/cmi5/context/categories/moveon',
    //     objectType: 'Activity'
    //   }];
    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        result: {
          score: {
            scaled: newScore / 100,
            raw: newScore,
            min: 0,
            max: 100
          },
          success: false,
          duration
        },
        context: {
          ...this.context.context,
          extensions: {
            ...this.context.context.extensions,
            'https://w3id.org/xapi/cmi5/context/extensions/masteryscore': this.masteryscore
          }
        },
        timestamp: TinCan.Utils.getISODateString(failedDate)
      });

    await this.sendStatement(statement);
  }

  terminatedStatementSent = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/terminated',
      display: {
        'en-US': 'terminated',
        'fr-FR': 'a terminé'
      }
    };

    const terminatedDate = new Date();
    const durationMS = terminatedDate.getTime() - this.initializedDate.getTime();

    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    const statement = new TinCan.Statement({
      actor: this.agent,
      verb,
      object: this.activity,
      result: {
        duration
      },
      ...this.context,
      timestamp: TinCan.Utils.getISODateString(terminatedDate)
    });
    return await this.sendStatement(statement);
  }

  endXapiSequence = async ({ score }) => {
    if (score !== null && !isNaN(score)) {
      if (this.masteryScore !== null && !isNaN(this.masteryScore) && score > this.masteryscore) {
        await this.sendPassedStatement(score);
      } else {
        if (score >= 70) {
          await this.sendPassedStatement(score);
        } else {
          await this.failedStatementSent(score);
        }
      }
    } else {
      /**
      todo: patch provisioire pour faire fonctionner maskott a virer en iteration 2
      **/
      await this.sendPassedStatement(score);
    }

    await this.completedStatementSent();
    await this.terminatedStatementSent();
  }
}

export default CMI5Maskott;
