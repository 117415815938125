import { call, select, put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  END_MODULE_SUCCESS,
  CUSTOM_LEAVE_MODULE,
  GO_TO_END_MODULE,
  GO_TO_CUSTOM_END
} from '../actions';
import {
  getAuthData,
  getLtiInfo,
  getModulePoints
} from '../selectors';
import {
  sendGradeToComsumer
} from '../services/api/lti';
import logger from '@frello-tech/front-utils/dist/utils/logger';

function * flow () {
  const { isLti } = yield select(getLtiInfo);
  if (isLti) {
    yield takeLatest(END_MODULE_SUCCESS, sendGradeToConsumer);
    yield takeLeading(GO_TO_CUSTOM_END, goToCustomEnd);
    yield takeLeading(CUSTOM_LEAVE_MODULE, redirectToReturnUrl);
  }
}

function * goToCustomEnd () {
  yield put({ type: GO_TO_END_MODULE });
}

export function * sendGradeToConsumer ({ payload: { survey } }) {
  try {
    const points = yield select(getModulePoints);
    const score = survey.score && !isNaN(points) && points > 0 ? (100 / points) * survey.score : undefined;
    if (!isNaN(score)) {
      const { sessionID } = yield select(getLtiInfo);
      const authData = yield select(getAuthData);
      yield call(sendGradeToComsumer, authData, { sessionID, grade: (Math.round(score) / 100) });
    }
  } catch (e) {
    yield call(console.log, 'endXapiSequence error');
    logger.error(e);
  }
}

function * redirectToReturnUrl () {
  const user = yield select(getLtiInfo);
  if (user.launch_presentation_return_url && window) { // vérifier que c'est une url valide
    window.top.location.href = user.launch_presentation_return_url;
  }
}

export default flow;
