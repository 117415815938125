import Immutable from 'seamless-immutable';
import {
  LOAD_SOUND_SUCCESS,
  UNLOAD_SOUND_SUCCESS,
  PLAY_SOUND_SUCCESS,
  PAUSE_SOUND_SUCCESS,
  RECORD_SOUND_START_SUCCESS,
  RECORD_SOUND_END_SUCCESS,
  CURRENT_PERMISSION_AUDIO_RECORDING,
  LOAD_SOUND,
  PLAY_SOUND_ERROR
} from '../actions';

export const INITIAL_STATE = Immutable({
  sounds: {},
  current: null,
  record: { haveRecordingPermissions: false },
  loading: false
});

const audio = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  case RECORD_SOUND_END_SUCCESS:
    state = {
      ...state,
      sounds:
        {
          ...state.sounds,
          [payload.src]: {
            sound: payload.sound,
            status: 'pause'
          }
        },
      record: { ...state.record, status: 'recorded' }
    };
    return state;
  case LOAD_SOUND:
    state = {
      ...state,
      loading: true
    };
    return state;
  case LOAD_SOUND_SUCCESS: {
    state = {
      ...state,
      sounds:
        {
          ...state.sounds,
          [payload.src]: {
            sound: payload.sound,
            status: 'pause'
          }
        },
      loading: false
    };
    return state;
  }
  case PLAY_SOUND_SUCCESS:
    state = {
      ...state,
      current: payload.src,
      sounds:
        {
          ...state.sounds,
          [payload.src]: {
            sound: state.sounds[payload.src].sound,
            status: 'play'
          }
        }
    };
    return state;
  case PLAY_SOUND_ERROR:
    state = {
      ...state,
      current: payload.src,
      sounds:
        {
          ...state.sounds,
          [payload.src]: {
            sound: state.sounds[payload.src].sound,
            status: 'error'
          }
        }
    };
    return state;
  case PAUSE_SOUND_SUCCESS:

    state = {
      ...state,
      sounds:
        {
          ...state.sounds,
          current: state.current === payload.src ? '' : state.current,
          [payload.src]: {
            sound: state.sounds[payload.src].sound,
            status: 'pause'
          }
        }
    };
    return state;
  case UNLOAD_SOUND_SUCCESS:
  {
    const { [payload.src]: value, ...sounds } = state.sounds;
    state = { ...state, sounds, current: state.current === payload.src ? '' : state.current };
    return state;
  }
  case CURRENT_PERMISSION_AUDIO_RECORDING:
    state = { ...state, record: { ...state.record, haveRecordingPermissions: payload.status } };
    return state;
  case RECORD_SOUND_START_SUCCESS:
    state = { ...state, record: { ...state.record, ...payload, status: 'recording' } };
    return state;
  default:
    return state;
  }
};

export default audio;
