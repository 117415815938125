import { call, delay } from 'redux-saga/effects';
import logger from '@frello-tech/front-utils/dist/utils/logger';

export function * callApi (apiRequest, ...data) {
  for (let i = 0; i < 5; i++) {
    try {
      const apiResponse = yield call(apiRequest, ...data);
      return apiResponse;
    } catch (err) {
      logger.error(err);
      if (i < 4) {
        yield delay(2000);
      }
    }
  }
  // attempts failed after 5 attempts
  throw new Error('API request failed');
}
