import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  USER_LOGIN_SUCCESS,
  AUTO_CONNECT_IFRAME,
  FETCH_SECTION_SUCCESS,
  SET_CORRECTION_MODE_ONLY
} from '../actions';
import jwtDecode from 'jwt-decode';
import { loadModuleByIdWithActivities, loadSurvey } from './modules';
import { initCmi5Maskoot } from './CMI5Maskott';
import {
  getAuthData
} from '../selectors';
import logger from '@frello-tech/front-utils/dist/utils/logger';
import { fetchSection } from '../services/api/section';

function * flow () {
  yield takeLatest(AUTO_CONNECT_IFRAME, autoConnectIframe);
}

function * autoConnectIframe ({
  payload: {
    accessToken,
    moduleId,
    moduleVersionId,
    evaluationSessionId,
    sectionId,
    redirectURI,
    extension
  }
}) {
  try {
    if (accessToken && typeof accessToken === 'string') {
      if (redirectURI?.includes('corrections') && extension !== 'previsualisation') {
        yield put({ type: SET_CORRECTION_MODE_ONLY });
      }
      const decoded = yield call(jwtDecode, accessToken);
      yield put({ type: USER_LOGIN_SUCCESS, payload: { accessToken, ...decoded } });
      if (sectionId) {
        // evaluation mode
        const authData = yield select(getAuthData);
        const section = yield call(fetchSection, authData, { sectionId, evaluationSessionId });
        yield put({ type: FETCH_SECTION_SUCCESS, payload: { section } });
      }
      yield call(loadModuleByIdWithActivities, { payload: { moduleId, moduleVersionId } });
      yield call(loadSurvey, { payload: { moduleId, moduleVersionId } });
    }
    yield call(initCmi5Maskoot);
  } catch (e) {
    console.log('autoConnect iframe erreur auth');
    logger.error(e);
  }
}

export default flow;
