import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import themeStyle from './styles/theme.style';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: [
      'raleway'
    ].join(','),
    h1: {
      fontSize: '2rem', // 32px
      fontWeight: 700,
      color: '#373C45'
    },
    h2: {
      fontSize: '1.5rem', // 24px
      fontWeight: 500,
      color: '#373C45',
      textTransform: 'none !important'
    },
    h3: {
      fontSize: '1.25rem', // 20px
      fontWeight: 600,
      color: '#373C45',
      textTransform: 'none !important'
    },
    h4: {
      fontSize: '1.125rem', // 18px
      fontWeight: 500,
      color: '#373C45',
      textTransform: 'none !important'
    },
    h5: {
      fontSize: '1rem',
      color: '#373C45',
      fontWeight: 400
    },
    h6: {
      color: '#0033FF',
      fontSize: '1.125rem'
    },
    pBold: {
      fontSize: '1rem', // 16px
      color: '#373C45',
      fontWeight: 700
    }
  },
  palette: createPalette({
    primary: {
      main: '#0033FF',
      contrastText: '#575756'
    },
    secondary: {
      main: '#0033FF',
      contrastText: '#6f6f6e'
    },
    text: {
      primary: '#575756',
      secondary: '#6f6f6e'
    },
    success: {
      main: themeStyle.green[500]
    },
    warning: {
      500: '#FFCA04'
    },
    error: {
      main: '#f44336'
    },
    background: {
      default: '#FFF'
    }
  }),
  overrides: {
    MuiAlert: {
      standardWarning: {
        backgroundColor: '#FFF4CD'
      }
    }
  }
});

export default theme;
