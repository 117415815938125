import TinCan from 'tincanjs';
import logger from '@frello-tech/front-utils/dist/utils/logger';

const roundScore = score => Math.round(score * 100) / 100;

class CMI5 {
  static instance;

  lrs;

  static LAUNCH_MODE_VALUES = [
    'Normal', // Record all statements
    'Browse', // AU must Record Only "Initialized" and "Terminated"
    'Review' // AU must Record Only "Initialized" and "Terminated"
  ];

  category = [{
    objectType: 'Activity',
    id: 'https://w3id.org/xapi/cmi5/context/categories/cmi5'
  }];

  activity;

  registration;

  agent;
  /*
    moveOn Value = "Passed" : If the LMS receives a statement with the verb "Passed", then the LMS will consider the AU satisfied.
    moveOn Value = "Completed" : If the LMS receives a statement with the verb "Completed", then the LMS will consider the AU satisfied.
    moveOn Value = "CompletedAndPassed" : If the LMS receives statements with the verbs "Completed" and "Passed", then the LMS will consider the AU satisfied.
    moveOn Value = "CompletedOrPassed" : If the LMS receives a statement with either of the verbs "Completed" or "Passed", then the LMS will consider the AU satisfied.
    moveOn Value = "NotApplicable": The LMS will consider the AU satisfied.
  */
  moveOn;

  returnURL;

  masteryScore;

  launchParameters;

  launchMode;

  contextTemplate;

  entitlementKey; // my content connection token to check autority

  constructor (cfg) {
    if (CMI5.instance) {
      return CMI5.instance;
    }

    this.initTinCan(cfg);
    CMI5.instance = this;
  }

  get returnURL () {
    return this.returnURL;
  }

  getContextTemplate () {
    return this.contextTemplate
      ? this.contextTemplate
      : {
        contextActivities: {},
        extensions: {}
      };
  }

  initTinCan = (cfg) => {
    try {
      const { endpoint, 'auth-token': auth, actor, registration, activityId } = cfg;

      this.agent = TinCan.Agent.fromJSON(actor);
      this.registration = registration;
      this.activity = new TinCan.Activity({ id: activityId });

      this.lrs = new TinCan.LRS(
        {
          endpoint,
          auth: `Basic ${auth}`,
          allowFail: false,
          version: '1.0.0'
        }
      );
    } catch (ex) {
      console.log('Failed to setup LRS object: ', ex);
      logger.error(ex);
      return null;
    }
  }

  getLearnerPreferences = () => {
    return new Promise((resolve, reject) => {
      this.lrs.retrieveAgentProfile('cmi5LearnerPreferences',
        {
          agent: this.agent,
          callback: (err, result) => {
            if (err) {
              console.log('getLearnerPreferences error : ');
              logger.error(err);
              reject(err);
            } else {
              resolve(result);
            }
          }
        });
    });
  }

  sendStatement = statement => {
    return new Promise((resolve, reject) => {
      this.lrs.saveStatement(statement,
        {
          callback: (err, xhr) => {
            if (err) {
              logger.error(err);
              if (xhr !== null) {
                const { responseText, status } = xhr;
                console.log(`Failed to save statement: ${responseText}(${status})`);
              }
              reject(err);
            } else {
              resolve({});
            }
          }
        });
    });
  }

  initializedStatement = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/initialized',
      display: {
        en: 'initialized'
      }
    };

    this.initializedDate = new Date();

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        context: {
          registration: this.registration,
          contextActivities: {
            ...this.getContextTemplate().contextActivities,
            category: [...this.category]
          },
          extensions: {
            ...this.getContextTemplate().extensions
          }
        },
        timestamp: TinCan.Utils.getISODateString(this.initializedDate)
      });

    await this.sendStatement(statement);
  }

  sendPassedStatement = async ({ score = 0, points = 5 }) => {
    const verb = new TinCan.Verb('passed');
    const newScore = roundScore(score / points);
    const passedDate = new Date();
    const durationMS = passedDate.getTime() - this.initializedDate.getTime();
    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    // const category = [
    //   ...this.category,
    //   {
    //     id: 'https://w3id.org/xapi/cmi5/context/categories/moveon',
    //     objectType: 'Activity'
    //   }];

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        result: {
          score: {
            scaled: newScore,
            raw: score,
            min: 0,
            max: points
          },
          success: true,
          duration
        },
        context: {
          registration: this.registration,
          contextActivities: {
            ...this.getContextTemplate().contextActivities,
            category: [...this.category]
          },
          extensions: {
            ...this.getContextTemplate().extensions,
            'https://w3id.org/xapi/cmi5/context/extensions/masteryscore': this.masteryscore
          }
        },
        timestamp: TinCan.Utils.getISODateString(passedDate)
      });

    await this.sendStatement(statement);
  }

  failedStatementSent = async ({ score = 0, points = 5 }) => {
    const verb = new TinCan.Verb('failed');
    const newScore = roundScore(score / points);
    const failedDate = new Date();
    const durationMS = failedDate.getTime() - this.initializedDate.getTime();
    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    // const category = [
    //   ...this.category,
    //   {
    //     id: 'https://w3id.org/xapi/cmi5/context/categories/moveon',
    //     objectType: 'Activity'
    //   }];

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        result: {
          score: {
            scaled: newScore,
            raw: score,
            min: 0,
            max: points
          },
          success: false,
          duration
        },
        context: {
          registration: this.registration,
          contextActivities: {
            ...this.getContextTemplate().contextActivities,
            category: [...this.category]
          },
          extensions: {
            ...this.getContextTemplate().extensions,
            'https://w3id.org/xapi/cmi5/context/extensions/masteryscore': this.masteryscore
          }
        },
        timestamp: TinCan.Utils.getISODateString(failedDate)
      });

    await this.sendStatement(statement);
  }

  completedStatementSent = async () => {
    const verb = new TinCan.Verb('completed');

    const completedDate = new Date();
    const durationMS = completedDate.getTime() - this.initializedDate.getTime();
    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    // const category = [
    //   ...this.category,
    //   {
    //     id: 'https://w3id.org/xapi/cmi5/context/categories/moveon',
    //     objectType: 'Activity'
    //   }];

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object: this.activity,
        result: {
          completion: true,
          duration
        },
        context: {
          registration: this.registration,
          contextActivities: {
            ...this.getContextTemplate().contextActivities,
            category: [...this.category]
          },
          extensions: {
            ...this.getContextTemplate().extensions
          }
        },
        timestamp: TinCan.Utils.getISODateString(completedDate)
      });

    await this.sendStatement(statement);
  }

  progressStatementSent = async ({ completion = false, progress = 0, currentActivity }) => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/progressed',
      display: {
        en: 'progressed'
      }
    };

    const terminatedDate = new Date();
    const durationMS = terminatedDate.getTime() - this.initializedDate.getTime();

    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );
    const statement = new TinCan.Statement({
      actor: this.agent,
      verb,
      object: {
        ...this.activity,
        id: `${this.activity.id}/activity/${currentActivity}`
      },
      result: {
        duration,
        extensions: {
          'https://w3id&46;org/xapi/cmi5/result/extensions/progress': progress
        },
        completion
      },
      context: {
        registration: this.registration,
        contextActivities: {
          ...this.getContextTemplate().contextActivities,
          category: [...this.category]
        },
        extensions: {
          ...this.getContextTemplate().extensions
        }
      },
      timestamp: TinCan.Utils.getISODateString(terminatedDate)
    });
    return await this.sendStatement(statement);
  }

  terminatedStatementSent = async () => {
    const verb = {
      id: 'http://adlnet.gov/expapi/verbs/terminated',
      display: {
        en: 'terminated'
      }
    };

    const terminatedDate = new Date();
    const durationMS = terminatedDate.getTime() - this.initializedDate.getTime();

    const duration = TinCan.Utils.convertMillisecondsToISO8601Duration(
      durationMS
    );

    const statement = new TinCan.Statement({
      actor: this.agent,
      verb,
      object: this.activity,
      result: {
        duration
      },
      context: {
        registration: this.registration,
        contextActivities: {
          ...this.getContextTemplate().contextActivities,
          category: [...this.category]
        },
        extensions: {
          ...this.getContextTemplate().extensions
        }
      },
      timestamp: TinCan.Utils.getISODateString(terminatedDate)
    });
    return await this.sendStatement(statement);
  }

  getCurrentActivity = async () => {
    const currentActivity = await new Promise((resolve, reject) => {
      this.lrs.retrieveState(
        'ACTIVITY.currentStateData',
        {
          activity: this.activity,
          agent: this.agent,
          registration: this.registration,

          callback: (err, result) => {
            if (err) {
              console.log('launchData error');
              logger.error(err);
              reject(err);
            } else {
              if (!result || !result.contents) {
                reject(new Error('no_content'));
              } else {
                const contents = typeof result.contents === 'string' ? JSON.parse(result.contents) : result.contents;
                resolve(contents && contents.activityNumber ? contents : { activityNumber: 0 });
              }
            }
          }
        }
      );
    })
      .then(r => {
        if (r && r.activityNumber) {
          this.activityNumber = r.activityNumber;
          return r.activityNumber;
        } else {
          this.activityNumber = 0;
          return 0;
        }
      })
      .catch(e => 0);
    return currentActivity;
  }

  setCurrentActivity = async ({ activityNumber }) => {
    this.activityNumber = activityNumber;
    await new Promise((resolve, reject) => {
      this.lrs.saveState(
        'ACTIVITY.currentStateData',
        { activityNumber },
        {
          activity: this.activity,
          agent: this.agent,
          registration: this.registration,
          method: 'POST',
          contentType: 'application/jsons',
          callback: (err, result) => {
            if (err) {
              console.log('setCurrentActivity error : ');
              logger.error(err);
            } else {
              resolve(result);
            }
          }
        }
      );
    });
  }

  launchData = async () => {
    return new Promise((resolve, reject) => {
      this.lrs.retrieveState(
        'LMS.LaunchData',
        {
          activity: this.activity,
          agent: this.agent,
          registration: this.registration,
          callback: (err, result) => {
            if (err) {
              console.log('launchData error');
              logger.error(err);
              reject(err);
            } else {
              const contents = typeof result.contents === 'string' ? JSON.parse(result.contents) : result.contents;
              const {
                moveOn,
                launchMode,
                contextTemplate,
                masteryScore,
                returnURL = 'https://devadn.apolearn.com/xapi/redirect'
              } = contents;
              this.moveOn = moveOn;
              this.launchMode = launchMode;
              this.contextTemplate = contextTemplate;
              this.masteryScore = masteryScore;
              this.returnURL = returnURL;
              resolve(result);
            }
          }
        }
      );
    });
  }

  sendAnswered = async ({ result, activityCMI5, currentActivity }) => {
    const verb = new TinCan.Verb('answered');
    const completedDate = new Date();
    const object = {
      ...this.activity,
      id: `${this.activity.id}/activity/${currentActivity}`,
      ...activityCMI5
    };

    // const category = [
    //   ...this.category,
    //   {
    //     id: 'https://w3id.org/xapi/cmi5/context/categories/moveon',
    //     objectType: 'Activity'
    //   }];

    const statement = new TinCan.Statement(
      {
        actor: this.agent,
        verb,
        object,
        result,
        context: {
          registration: this.registration,
          contextActivities: {
            ...this.getContextTemplate().contextActivities,
            category: [...this.category]
          },
          extensions: {
            ...this.getContextTemplate().extensions
          }
        },
        timestamp: TinCan.Utils.getISODateString(completedDate)
      });

    await this.sendStatement(statement);
  }

  startXapiSequence = async () => {
    await this.launchData();
    await this.initializedStatement();
  }

  endXapiSequence = async ({ score, points }) => {
    const scorePourcent = !isNaN(score) && score > 0 ? (score / points) : 0;
    if (scorePourcent !== null && !isNaN(scorePourcent)) {
      if (this.masteryScore !== null && !isNaN(this.masteryScore) && scorePourcent > this.masteryscore) {
        await this.sendPassedStatement({ score, points });
      } else {
        if (scorePourcent >= 0.7) {
          await this.sendPassedStatement({ score, points });
        } else {
          await this.failedStatementSent({ score, points });
        }
      }
    }
    await this.completedStatementSent();
    await this.terminatedStatementSent();
  }
}

export default CMI5;
