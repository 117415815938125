import { combineReducers } from 'redux';
import user from './user';
import models from './models';
import ui from './ui';
import audio from './audio';
import section from './section';
import {
  USER_LOGOUT_SUCCESS
} from '../actions';

const appReducer = combineReducers({
  user,
  models,
  ui,
  audio,
  section
});

const rootReducer = (state, action) => {
  // console.log(action.type);
  if (action.type === USER_LOGOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
