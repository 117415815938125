import Immutable from 'seamless-immutable';
import { FETCH_SECTION_SUCCESS } from '../actions';

export const INITIAL_STATE = Immutable({
});

const section = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  case FETCH_SECTION_SUCCESS:
    state = {
      ...state,
      ...payload.section
    };
    return state;
  default:
    return state;
  }
};

export default section;
