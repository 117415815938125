import { schema } from 'normalizr';

export const blocSchema = new schema.Entity('blocs');

export const surveySchema = new schema.Entity('surveys');

export const programSchema = new schema.Entity('programs');

export const moduleSchema = new schema.Entity('modules',
  {
    blocs: [blocSchema],
    questionnaires: [surveySchema]
  }
);
