export const getAuthorization = async (authData) => {
  if (!authData) {
    throw new TypeError('Missing argument \'authData\'');
  }
  const Authorization = authData.accessToken;
  const userAgent = authData.userAgent;
  const userAgentObject = userAgent && typeof window === 'undefined' ? { 'user-agent': userAgent } : {};
  return {
    headers: {
      Authorization,
      'Access-Control-Allow-Origin': '*',
      ...userAgentObject
    }
  };
};

export const getJWTBody = async (authData) => {
  if (!authData) {
    throw new TypeError('Missing argument \'authData\'');
  }
  return JSON.parse(Buffer.from(authData.accessToken.split('.')[1], 'base64').toString());
};

export const getAccessToken = async (authData) => {
  if (!authData) {
    throw new TypeError('Missing argument \'authData\'');
  }
  return authData.accessToken;
};
