import memoize from 'lodash.memoize';
import i18n from 'i18n-js';
import ar from '../../static/locales/ar/common.json';
import fr from '../../static/locales/fr/common.json';
import en from '../../static/locales/en/common.json';
// https://github.com/react-native-community/react-native-localize/blob/master/example/src/SyncExample.js
export const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

const translationGetters = {
  // lazy requires (metro bundler does not support symlinks)
  'ar-SA': ar,
  'en-EN': en,
  'fr-FR': fr,
  ar,
  en,
  fr
};

export const LANGUAGE_ID = {
  'ar-SA': 3,
  ar: 3,
  'en-EN': 1,
  en: 1,
  'fr-FR': 2,
  fr: 2,
  'es-ES': 4,
  es: 4,
  'de-DE': 5,
  de: 5
};
export const getLanguageId = () => {
  return LANGUAGE_ID[i18n.locale] ? LANGUAGE_ID[i18n.locale] : 2;
};

export const getLanguage = (searchedLanguageId = false, fallback = false) => {
  if (!searchedLanguageId && !fallback) {
    return i18n.locale ? i18n.locale : 'fr';
  }
  for (const lang in LANGUAGE_ID) {
    if (Object.hasOwnProperty.call(LANGUAGE_ID, lang)) {
      const languageId = LANGUAGE_ID[lang];
      if (languageId === searchedLanguageId) {
        return lang;
      }
    }
  }
  return fallback;
};

export const setI18nConfig = (language = 'fr') => {
  if (!translationGetters[language]) {
    if (language) {
      const otherFormat = language.split('-')[0];
      if (translationGetters[otherFormat]) {
        language = otherFormat;
      } else {
        language = 'en-EN';
      }
    } else {
      language = 'en-EN';
    }
  }
  translate.cache.clear();
  i18n.fallbacks = true;
  i18n.translations = {
    [language]: translationGetters[language]
  };
  i18n.locale = language;
  return language;
};
