import mixpanel, { type Dict, type Query } from 'mixpanel-browser'
import {MIXPANEL_TOKEN} from '../../js/constants/api.js'

let mixpanelInstance: MixPanel | null = null
const token = MIXPANEL_TOKEN

export const getMixPanelClient = () => {
  if (!mixpanelInstance) {
    mixpanelInstance = new MixPanel()
  }
  return mixpanelInstance
}

class MixPanel {
  constructor () {
    mixpanel.init(token, { debug: false, ip: false, ignore_dnt: true })
  }

  identify (id: string) {
    mixpanel.identify(id)
  }

  alias (id: string) {
    mixpanel.alias(id)
  }

  track (event: any, props?: Dict) {
    mixpanel.track(event, {...props, project: 'module-viewer-web-app'})
  }

  set (props: Dict) {
    mixpanel.people.set(props)
  }
}

export default MixPanel
