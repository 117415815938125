import { call, put, select, take, takeLatest, takeEvery } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import {
  PLAY_SOUND,
  PAUSE_SOUND,
  LOAD_SOUND,
  LOAD_SOUND_SUCCESS,
  UNLOAD_SOUND,
  UNLOAD_SOUND_SUCCESS,
  PAUSE_SOUND_SUCCESS,
  PLAY_SOUND_SUCCESS,
  RECORD_SOUND_START,
  RECORD_SOUND_START_SUCCESS,
  RECORD_SOUND_END,
  RECORD_SOUND_END_SUCCESS,
  PLAY_SOUND_ERROR
} from '../actions';
import { getSound, getCurrentSoundPlaying, getCurrentRecord } from '../selectors';
import logger from '@frello-tech/front-utils/dist/utils/logger';

function * flow () {
  yield takeLatest(PLAY_SOUND, play);
  yield takeLatest(PAUSE_SOUND, pause);
  yield takeEvery(LOAD_SOUND, load);
  yield takeEvery(UNLOAD_SOUND, unload);
  yield takeLatest(RECORD_SOUND_START, startRecording);
  yield takeLatest(RECORD_SOUND_END, endRecording);
}

function * endRecording () {
  try {
    const { record } = yield select(getCurrentRecord);
    record.stop();
  } catch (error) {
    yield call(console.log, 'endRecording error');
    logger.error(error);
  }
}

function * startRecording () {
  const { sound: oldSOund } = yield select(getSound, 'recording');
  try {
    const channel = eventChannel(emitter => {
      navigator.getUserMedia = navigator.getUserMedia ||
                         navigator.webkitGetUserMedia ||
                         navigator.mozGetUserMedia;

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          let options = { };
          if (MediaRecorder.isTypeSupported(options.mimeType)) {
            options = { mimeType: 'audio/webm;codecs=opus' };
          }
          const mediaRecorder = new MediaRecorder(stream, options);
          const audioChunks = [];
          mediaRecorder.addEventListener('dataavailable', event => {
            audioChunks.push(event.data);
          });
          mediaRecorder.addEventListener('start', () => {
            emitter({ status: 'start', mediaRecorder });
          });
          mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(audioChunks, {
              type: 'audio/webm'
            });
            const audioUrl = URL.createObjectURL(audioBlob);
            let sound;
            if (oldSOund) {
              sound = oldSOund;
              sound.src = audioUrl;
              sound.load();
            } else {
              sound = new Audio(audioUrl);
            }
            const callBack = () => emitter(END);
            emitter({ status: 'stop', sound, callBack });
            stream.getTracks().forEach(track => track.stop());
          });
          mediaRecorder.start();
        })
        .catch(function (error) {
          console.error('audio error');
          console.error(error);
        });
      return () => {
      // Perform any cleanup you need here
      };
    });

    while (true) {
      const { status, sound, mediaRecorder, callBack } = yield take(channel);
      if (!status) {
        break;
      }
      if (status === 'stop') {
        yield put({ type: RECORD_SOUND_END_SUCCESS, payload: { sound, src: 'recording' } });
        yield call(addEventStatus, { src: 'recording', sound, callBack });
      }
      if (status === 'start') {
        yield put({ type: RECORD_SOUND_START_SUCCESS, payload: { record: mediaRecorder } });
      }
    }
  } catch (e) {
    yield call(console.log, 'error in startRecording ');
    logger.error(e);
  }
}

function * play ({ payload }) {
  try {
    const { src } = payload;
    const { sound } = yield select(getSound, src);
    if (sound) {
      const currentSound = yield select(getCurrentSoundPlaying);
      if (currentSound && src !== currentSound) {
        yield call(pause, { payload: { src: currentSound } });
      }
      yield call(sound.play.bind(sound));
      yield put({ type: PLAY_SOUND_SUCCESS, payload: { status: 'play', src } });
    }
  } catch (e) {
    const { src } = payload;
    yield put({ type: PLAY_SOUND_ERROR, payload: { status: 'error', src } });
    yield call(console.log, 'erreur player audio ' + payload.src);
    logger.error(e);
  }
}
export function * pauseCurrentAudio () {
  try {
    const currentSound = yield select(getCurrentSoundPlaying);
    if (currentSound) {
      yield call(pause, { payload: { src: currentSound } });
    }
  } catch (e) {
    logger.error(e);
  }
}

function * pause ({ payload }) {
  try {
    const { src } = payload;
    const { sound } = yield select(getSound, src);
    if (sound) {
      yield call(sound.pause.bind(sound));
      yield put({ type: PAUSE_SOUND_SUCCESS, payload: { status: 'pause', src } });
    }
  } catch (e) {
    yield call(console.log, 'erreur player audio ' + payload.src);
    logger.error(e);
  }
}

function * unload ({ payload }) {
  try {
    const { src } = payload;
    const { sound } = yield select(getSound, src);
    if (sound) {
      yield call(sound.removeAttribute.bind(sound), 'src');
      yield call(sound.load.bind(sound));
      yield put({ type: UNLOAD_SOUND_SUCCESS, payload: { status: 'pause', src } });
    }
  } catch (e) {
    yield call(console.log, 'error unload');
    logger.error(e);
  }
}

function * load ({ payload }) {
  try {
    const { src } = payload;
    const sound = new Audio(src);
    if (sound) {
      yield put({ type: LOAD_SOUND_SUCCESS, payload: { sound, src } });
      yield call(addEventStatus, { src, sound });
    }
  } catch (e) {
    logger.error(e);
  }
}

function * addEventStatus ({ src, sound, callBack }) {
  const channel = eventChannel(emitter => {
    sound.addEventListener('ended', () => {
      emitter({ src, status: 'ended' });
    });
    sound.addEventListener('emptied', () => {
      emitter(END);
    });
    return () => {
      // Perform any cleanup you need here
      if (callBack) {
        callBack();
      }
    };
  });

  while (true) {
    const { status, src } = yield take(channel);
    if (!status) {
      break;
    }
    if (status === 'ended') {
      yield call(sound.pause.bind(sound));
      yield put({ type: PAUSE_SOUND_SUCCESS, payload: { status: 'pause', src } });
    }
  }
}

export default flow;
